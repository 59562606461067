import * as React from "react"
import Layout from "../components/layout"

export default function ThankYou(props: any) {
  return (
    <Layout {...props}>
      <div className="container">
        <div className="contactUsContainer">
          <div className="success">
            <h1>Thank you for contacting us</h1>
            <p>
              We will get back to you soon. In the mean time you can go to
              <div style={{ marginTop: 20, textAlign: "center" }}>
                <a href="/" className="link-style-button">
                  home page
                </a>
              </div>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
